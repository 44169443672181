.works{
  color: $slate-800;
  font-family: $font-base;
  overflow-x: hidden;

  h2{
    color: $slate-800;
    font-size: 30px;

    & > div {
      line-height: 1.4;
      overflow: visible;
    }

    @include  lg{
      font-size: 42px;
    }
  }

  p{
    font-size: 16px;
    font-weight: normal;
    color: $slate-600;

    @include lg{
      font-size: 20px;
    }
  }

  .split-chars, .split-words, .split-lines{
    overflow: hidden;
  }

  .split-lines div{
    line-height: 1.8;
  }

  .fade-up, .fade-left, .fade-right{
    overflow: hidden;
  }

  &-grid{
    position: relative;

    &-item{
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      perspective: 1000px;
      transform-style: preserve-3d;
      min-height: 300px;

      img{
        display: none;
      }

      .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        padding: 30px 14px;
        opacity: 0;
        transform: scale3d(0.3, 1, 1);
        transition: all 0.4s $ease;
        perspective: 1000px;
        transform-style: preserve-3d;
        background: rgba($primary-500, 0.8);
        backdrop-filter: blur(10px);

        .text{
          position: absolute;
          text-align: center;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }

        h3{
          font-size: 28px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        p{
          font-size: 18px;
          margin-bottom: 18px;
          opacity: 0.8;
          color: #fff;
        }

        .link{
          background: rgba(#000, 0.5);
          padding: 8px 16px;
          display: flex;
          align-items: center;
          transition: all 0.4s $ease;
          transform: scale(1);

          span{
            display: block;
            overflow: hidden;
            white-space: nowrap;
            font-weight: bold;
            margin-right: 10px;
          }

          svg{
            width: 24px;
            stroke: #fff;
            transform: rotate(0deg);
            transition: all 0.4s $ease;
          }

          &:hover{
            transform: scale(0.9);

            svg {
              transform: rotate(-45deg);
            }
          }
        }
      }

      &:hover .overlay {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }

      &.koolio .overlay{
        background: rgba(#581c87, 0.8);
      }

      &.real-impact .overlay {
        background: rgba(#ffbc00, 0.8);
      }

      &.nokap-coffee .overlay{
        background: rgba(#9A5049, 0.8);
      }

      &.roadstab .overlay{
        background: rgba(#f34610, 0.8);
      }

      &.longsip .overlay {
        background: rgba(#e88031, 0.8);
      }

      .web-works .overlay {
        background: rgba(#3b82f6, 0.8);
      }
    }
  }

  &-banner{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    position: relative;

    @include lg{
      height: 100vh;
    }

    &::after{
      content:"";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba($slate-900, 0.8);
    }

    .parallax-text{
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      padding-top: 5vh;

      .layer-heading{
        font-family: $font-base;
        font-size: 5vw;
        line-height: 1;
        color: #fff;
        font-weight: 800;
        display: block;
        text-transform: uppercase;
        position: relative;
        font-size: 38px;

        &::after{
          content:"";
          display: block;
          margin: 15px auto;
          width: 50%;
          height: 1px;
          background-image: linear-gradient(90deg, rgba(#fff, 0) 0%, #fff 50%, rgba(#fff, 0) 100%);
        }


        @include lg{
          font-size: 5vw;
        }
      }

      .layer-text{
        font-family: $font-base;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        font-size: 16px;
        color: #fff;

        @include lg{
          font-size: 18px;
        }
      }
    }
  }

  &-intro{

    h2{
      margin-bottom: 30px;
    }

    p{
      font-size: 18px;
      color: $slate-600;

      div{
        overflow: hidden;
      }

      @include lg {
        font-size: 20px;
      }
    }
  }

  &-service{
    margin-top: 30px;
    position: relative;

    &:before{
      content:"";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 200px;
      height: 2px;
      background: #fff;
      background-image: linear-gradient(90deg, $slate-50 0%, $slate-500 50%, $slate-50 100%);
    }

    ul{
      margin-top: 40px;
      text-align: center;
      font-size: 20px;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 20px;

      @include lg{
        padding: 0;
      }

      &:after{
        content: attr(data-text);
        display: block;
        text-transform: uppercase;
        font-weight: 800;
        position: absolute;
        text-align: center;
        top: -25px;
        width: 100%;
        font-size: 4vw;
        z-index: -1;
        opacity: 1;
        letter-spacing: .1em;
        color: $slate-500;
        opacity: 0.1;
        display: none;

        @include lg{
          display: block;
        }
      }

      li{

        span{
          position: relative;
          display: block;
          overflow: hidden;
          font-weight: 400;
          color: $slate-800;
          line-height: 2;
          margin: 5px 10px;
          z-index: 1;
          font-size: 20px;

          &:after {
            pointer-events: none;
            // background-image: linear-gradient(90deg, $primary-50 0%, $primary-500 100%);
            background: $primary-500;
            content: "";
            height: 1px;
            position: absolute;
            left: auto;
            top: auto;
            right: 0%;
            bottom: 0%;
            width: 0%;
            transition: all 0.6s cubic-bezier(0.12, 0, 0.39, 0);
            z-index: -1;
          }
        }

        &:hover{
          span{
            color: $primary-500;
          }

          span:after {
            width: 100%;
            right: auto;
            left: 0%;
          }
        }
      }
    }
  }

  &-inner-banner{
    height: 50vh;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @include lg{
      min-height: 100vh;
    }

    .shrink {
      display: block;
      position: absolute;
      width: 20%;
      height: 100%;
      background: #fff;
      top: 0;

      &.left {
        left: 0;
        transform-origin: left center;
      }

      &.right {
        right: 0;
        transform-origin: right center;
      }
    }

    &::after{
      content:"";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($slate-900, 0.8);
      z-index: -1;
    }

    h2{
      line-height: 1;
      text-align: center;
      color: #fff;
      font-size: 30px;

      @include lg{
        font-size: 4vw;
      }
    }
  }

  .videos-swiper{
    padding-bottom: 40px;

    .swiper-wrapper{
      overflow: hidden;

      @include lg{
        overflow: visible;
      }
    }

    .swiper-slide{
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      border: solid 1px $black-300;
      overflow: hidden;

      &-shadow{
        background: #fff;
        border-radius: 20px;

        @include lg{
          border-radius: 10px;
        }
      }

      @include lg {
        padding: 30px;
        border-radius: 20px;
      }
    }

    .swiper-pagination{
      bottom: 0px;

      &-bullet{
        border: solid 1px $black-900;
        background: none;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: hidden;
        opacity: 1;


        &:before{
          content:"";
          position: absolute;
          width: 100%;
          height: 100%;
          background: $black-900;
          left: 0;
          top: 0;
          border-radius: 50%;
          transform-origin: center;
          transform: scale(0);
          transition: all 0.4s $ease;
        }

        &-active{

          &::before{
            transform: scale(1);
          }
        }

      }
    }

    .swiper-button{

      &-prev, &-next{
        z-index: 6;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        border: solid 1px $black-300;
        width: 40px;
        height: 40px;

        &:after{
          display: none;
        }

        @include lg{
          width: 60px;
          height: 60px;
        }
      }

      &-prev{
        left: -10px;

        @include lg{
          left: -30px;
        }
      }

      &-next{
        right: -10px;

        @include lg{
          right: -30px;
        }
      }
    }

    a {
      background-color: #fff;
      border: 1px solid #d6d6d6;
      border-radius: 50%;
      padding: 1rem
    }


  }

  .video-wrapper .wrapper{
    overflow: visible;
    height: auto;

    @include lg{
      overflow: visible;
    }
  }

  .wrapper{
    display: block;
    position: relative;
    height: 25vh;
    overflow: hidden;

    @include lg{
      height: 80vh;
    }

    .desktop-holder {
      display: block;
      width: 90%;
      height: 100%;
      position: absolute;
      z-index: 500;
      transition-delay: 0.5s;
      transition: 0.8s cubic-bezier(.32, .31, .05, 1) 0.45s;
      border-radius: 2vw 2vw 0 0;
      overflow: hidden;
      box-shadow: inset 0px 1px 0 rgba(255, 255, 255, 0.1);

      opacity: 1;
      left: 0;
      right: 0;
      margin: 0 auto;

      @include lg{
        width: 80%;
        transform: translateX(-80px);
      }


      img {
        animation-delay: 1s;
      }

      .display {
        background-color: #1A1A1A;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(../images/desktop-screen.svg);
        height: 100%;
        padding: 2.7vw;
      }

      .screen {
        overflow: hidden;
        background: rgba(0, 0, 0, 0.4);
        border: 2px solid rgba(0, 0, 0, 0.4);
        border-radius: 2px;


        img {
          display: block;
          width: 100%;
          backface-visibility: hiddem;
          transform: translateY(0);
          transition: 3s cubic-bezier(.5, 0, .5, 1);
        }

        &:hover {
          img {
            transform: translateY(-50%);
            transition: 4s cubic-bezier(.5, 0, .5, 1);
          }
        }
      }
    }

    .iphone-holder {
      padding: 3.5%;
      width: 30%;
      height: 80%;
      position: absolute;
      right: 20px;
      bottom: 30px;
      z-index: 1000;
      transition-delay: 0.5s;
      transition: 0.8s cubic-bezier(.32, .31, .05, 1) 0.45s;
      transform: translateX(2vw);
      display: none;

      @include lg{
        display: block;
      }

      .device {
        padding: 25% 6% 0;
        background: url(../images/iphone.png) no-repeat top;
        background-size: 100%;
        overflow: hidden;
      }

      .screen {
        overflow: hidden;

        img {
          transform: translateY(0);
          transition: 3s cubic-bezier(.5, 0, .5, 1);
        }

        &:hover {
          img {
            transform: translateY(-40%);
            transition: 4s cubic-bezier(.5, 0, .5, 1);
          }
        }
      }
    }
  }

  .page-wrapper .container{

    .btn {
      transform: scale(0.8);
      display: flex;
      justify-content: center;
      width: fit-content;

      .btn-text {
        text-align: center;
        display: block;
        width: 100%;
        justify-content: center;
      }

      @include lg {
        transform: unset;
        min-width: auto;
      }
    }
  }
}
