// Banners
.home-banners {
  position: relative;
  overflow: hidden;
  background: hsl(0, 0%, 90%);
  height: 100vh;
  z-index: 1;

  .bannerTxt-mobile {
    display: none;

    @media (max-width : $screen-md ) {
      display: block;
      padding-top: 15vh;
      text-align: center;
      padding: 15vh 20px 0;

      h2 {
        font-size: 36px;
        margin-bottom: 10px;

        br {
          display: none;
        }
      }

      p{

        br{
          display: none;
        }
      }
    }

    @media (max-width : $screen-sm ) {
      display: block;
      padding-top: 18vh;
      text-align: center;

      h2{
        font-size: 32px;
        line-height: calc(32px * 1.4);
        text-transform: uppercase;

        br{
          display: none;
        }
      }
    }
  }

  .banner-txt {
    position: absolute;
    z-index: 10;
    bottom: 35%;
    left: 5vw;
    width: 50%;
    pointer-events: none;

    @media (max-width : $screen-lg ) {
      display: none;
    }

    h2 {
      text-transform: uppercase;
      letter-spacing: 0;
      position: relative;
      min-height: 150px;
      overflow: hidden;
      color: $sec-900;
    }

    h2 > div {
      display: block;
      position: absolute;
      text-transform: uppercase;
      left: 0;
      top: 0;
    }

    h2 span,
    h2.text {
      font-size: 4.5vw;
      display: inline-block;
      line-height: 1;
      text-transform: uppercase;
      color: $slate-900;
      position: relative;
      font-weight: 900;
      font-family: $font-base;
      letter-spacing: 0.04em
    }

    p {
      margin-top: 30px;
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
      color: $slate-900;
      text-transform: uppercase;
      letter-spacing: 0.04em;
    }
  }

  .illiustrations {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;

    &.il-active {
      display: block;
    }
  }

  .moto {

    .moto-bike {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100px;
      background: rgba(0, 0, 0, 0);
      margin: auto;
      z-index: 1;

      &:hover~.moto-wrapper {

        .smoke span:nth-child(1) {
          -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }

        .smoke span:nth-child(2) {
          -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
        }

        .smoke span:nth-child(3) {
          -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
        }

        .smoke span:nth-child(4) {
          -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
        }

        .smoke span:nth-child(5) {
          -webkit-animation-delay: 1s;
          animation-delay: 1s;
        }

        .smoke span:nth-child(6) {
          -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
        }

        .smoke span:nth-child(7) {
          -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
        }

        .smoke span:nth-child(8) {
          -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
        }

        .smoke span:nth-child(9) {
          -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
        }

        ~.moto-wrapper .smoke span:nth-child(10) {
          -webkit-animation-delay: 2s;
          animation-delay: 2s;
        }
      }
    }

    .moto-wrapper {
      display: block;
      position: absolute;
      top: 0;
      bottom: -60px;
      right: 50px;
      margin: auto;
      width: 1100px;
      height: 600px;
      overflow: visible;

      @media (max-height: 800px){
        transform: scale(0.8) translateX(60px);
      }

      @media (max-width: $screen-lg ) {
        bottom: -120px;
        right: 0;
        left: 0;
        max-width: 100%;
        transform: none;
      }
    }

    .light {
      display: block;
      margin: -1930px 0 0;
      position: absolute;
      right: 780px;
      top: 50%;
      height: 4000px;
      -webkit-transform-origin: 1070px 1730px 0;
      -ms-transform-origin: 1070px 1730px 0;
      transform-origin: 1070px 1730px 0;
      z-index: 2;
      opacity: 0;
      pointer-events: none;

      @media (max-width: $screen-lg) {
        display: block;
        margin: 0;
        position: absolute;
        right: 70%;
        top: -97%;
        height: auto;
        transform-origin: 0 0 0;
        z-index: 2;
        opacity: 1;
        pointer-events: none;
        animation: flicker 1s infinite linear;
      }
    }

    .smoke {
      display: block;
      position: absolute;
      bottom: 50px;
      right: 50px;
      width: 300px;
      height: 120px;
      overflow: visible;
      z-index: -1;
      pointer-events: none;

      span {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        animation: smoke 1s linear infinite;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        -wibkit-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          background-color: hsl(0, 0%, 70%);
          border-radius: 50%;
        }

        &:before {
          width: 20px;
          height: 20px;
          bottom: 25px;
        }

        &:after {
          width: 30px;
          height: 30px;
          top: 0;
          right: 10px;
        }

        &:nth-child(1) {
          -webkit-animation-delay: 0.3333333333s;
          animation-delay: 0.3333333333s;
        }

        &:nth-child(2) {
          -webkit-animation-delay: 0.6666666667s;
          animation-delay: 0.6666666667s;
        }

        &:nth-child(3) {
          -webkit-animation-delay: 1s;
          animation-delay: 1s;
        }

        &:nth-child(4) {
          -webkit-animation-delay: 1.3333333333s;
          animation-delay: 1.3333333333s;
        }

        &:nth-child(5) {
          -webkit-animation-delay: 1.6666666667s;
          animation-delay: 1.6666666667s;
        }

        &:nth-child(6) {
          -webkit-animation-delay: 2s;
          animation-delay: 2s;
        }

        &:nth-child(7) {
          -webkit-animation-delay: 2.3333333333s;
          animation-delay: 2.3333333333s;
        }

        &:nth-child(8) {
          -webkit-animation-delay: 2.6666666667s;
          animation-delay: 2.6666666667s;
        }

        &:nth-child(9) {
          -webkit-animation-delay: 3s;
          animation-delay: 3s;
        }

        &:nth-child(10) {
          -webkit-animation-delay: 3.3333333333s;
          animation-delay: 3.3333333333s;
        }
      }

      @media (max-height: 800px ) {
        transform: scale(1) translate(0, -20px);
      }

      @media (max-width : $screen-lg){
        bottom: 20px;
        right: 0;
        transform: scale(1) translate(0%, -40%);

        span:nth-child(1) {
          animation-delay: 0.2s;
        }

        span:nth-child(2) {
          animation-delay: 0.4s;
        }

        span:nth-child(3) {
          animation-delay: 0.6s;
        }

        span:nth-child(4) {
          animation-delay: 0.8s;
        }

        span:nth-child(5) {
          animation-delay: 1s;
        }

        span:nth-child(6) {
          animation-delay: 1.2s;
        }

        span:nth-child(7) {
          animation-delay: 1.4s;
        }

        span:nth-child(8) {
          animation-delay: 1.6s;
        }

        span:nth-child(9) {
          animation-delay: 1.8s;
        }

        span:nth-child(10) {
          animation-delay: 2s;
        }
      }

      @media (max-width: $screen-sm){
        transform: scale(0.4) translate(200px, 40px);
      }

    }
  }

  .fun {

    svg {
      position: absolute;
      width: 500px;
    }

    .fan {
      position: absolute;
      width: 460px;
      height: 570px;
      top: 3vh;
      bottom: 0;
      right: 25vw;
      margin: auto;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
    }

    .air {
      z-index: 4;
      pointer-events: none;
    }

    .fun-1 {
      z-index: 2;
    }

    .fun-2 {
      z-index: 1;
      width: 330px;
      top: 60px;
    }

    .fun-3 {
      width: 100%;
    }

    .fan-lines {
      display: block;
      position: absolute;
      bottom: -238px;
      right: -200px;
      left: auto;
      width: 175%;
      max-width: none;
      pointer-events: none;
      z-index: -1;
    }

    @media (max-height: 800px) {

      .fan {
        transform: scale(0.9) translate(30%, 10%);
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    @media (max-width : $screen-lg ) {

      .fan {
        transform: scale(0.7) translate(-5%, 48%);
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .fun-2 {
        animation: fanWing 0.8s linear infinite;
      }

      .air {
        animation: fanAir 0.8s linear infinite forwards;
      }
    }

    @media (max-width: $screen-sm) {

      .fan{
        transform: scale(0.4) translate(-27%, 70%);
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  .sofa-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;

    .sofa {
      position: absolute;
      bottom: -130px;
      top: 0;
      right: 10%;
      height: 500px;
      margin: auto;

      img {
        float: left;
        display: block;
        height: 500px;
        max-width: none;
      }

      .sofa-middle {
        float: left;
        height: 500px;
        overflow: hidden;
        width: 0;
        background: url(../images/banner/sofa-middle.png) repeat-x top left/auto 100%;
      }

      .sofa-middle img {
        visibility: hidden;
      }

      .sofa-middle .banner-txt {
        left: 0;
        right: 0;
        margin: auto;
        bottom: auto;
        -webkit-transform: translateY(69px);
        -ms-transform: translateY(69px);
        transform: translateY(69px);
      }

      .sofa-middle .banner-txt h2.txt {
        min-height: 180px;
        width: 100%;
      }
    }

    @media (max-width: $screen-md) {

      .sofa {
        position: absolute;
        bottom: -130px;
        top: 0;
        right: -50px;
        left: -60px;
        margin: 0 auto;
        height: 300px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 300px;
          float: none;
        }

        .sofa-middle {
          width: 20px;
          height: 300px;
          animation: sofaExpand linear infinite 3s alternate;

          img {
            height: 300px;
          }
        }
      }
    }

    @media (max-width: $screen-sm){

      .sofa{
        height: 200px;

        img{
          height: 200px;
          float: none;
        }

        .sofa-middle{
          width: 20px;
          height: 200px;

          img{
            height: 200px;
          }
        }
      }
    }



    .banner-txt {

      h2.txt {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        font-size: 4.5vw;
        font-weight: 900;
        font-family: $font-base;
        line-height: 1;
        letter-spacing: 0.04em
      }
    }
  }

  .tv {

    .tv-wrapper {
      width: 660px;
      height: 570px;
      top: -40px;
      bottom: 0;
      right: 13%;
      position: absolute;
      margin: auto;
      transform: scale(0.9);

      &:hover {

        .knob-1 {
          -webkit-animation: rotatKnob 2s steps(2, end) forwards infinite;
          animation: rotatKnob 2s steps(2, end) forwards infinite;
        }

        .knob-2 {
          -webkit-animation: rotatKnob 3s steps(4, end) forwards infinite;
          animation: rotatKnob 3s steps(4, end) forwards infinite;
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }

        .pics {
          z-index: 4;
        }

        .pics img {
          -webkit-animation: picsMove 5s infinite alternate;
          animation: picsMove 5s infinite alternate;
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }

        &~.banner-txt .glitch::before {
          animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
          opacity: 0.7;
        }

        &~.banner-txt .glitch::after {
          animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite reverse;
          opacity: 0.7;
        }
      }

      img {
        display: block;
        max-width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        backface-visibility: hidden;

        &.knob-1,
        &.knob-2 {
          width: 70px;
          left: auto;
          right: 98px;
          -webkit-transform-origin: center center;
          -ms-transform-origin: center center;
          transform-origin: center center;
          z-index: 3;
          pointer-events: none;
        }

        &.knob-1 {
          top: -325px;
          left: auto;
        }

        &.knob-2 {
          top: -165px;
        }

        &[alt=tv] {
          z-index: 5;
        }
      }

      .tv-giphy {
        position: absolute;
        width: 400px;
        height: 400px;
        background: url(../images/banner/tv-giphy.gif) no-repeat;
        background-size: contain;
        top: 25px;
        left: 40px;
        bottom: 0;
        margin: auto;
        z-index: 2;
        opacity: 1;
        -webkit-transition: all 0.5s linear 0.3s;
        transition: all 0.5s linear 0.3s;
        pointer-events: none;
      }

      .pics {
        width: 385px;
        height: 310px;
        position: absolute;
        top: 93px;
        left: 60px;
        bottom: 0;
        overflow: hidden;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: inset 0 0 60px 20px $black-900;
          box-shadow: inset 0 0 60px 20px $black-900;
          z-index: 3;
        }

        img {
          position: relative;
          transform: translateY(10px);
          width: 100%;
        }
      }
    }

    .glitch {
      position: relative;
      color: $black-900;
      z-index: 1;

      &:before,
      &:after {
        display: block;
        content: attr(data-glitch);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      &:before {
        color: #0ff;
        z-index: -1;
      }

      &:after {
        color: #ff00ff;
        z-index: -2;
      }
    }

    @media (max-width: $screen-sm ) {

      .tv-wrapper {
        width: 660px;
        height: 570px;
        top: -40px;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        margin: auto;
        transform: scale(0.4) translate(-45%, 50%);

        img.knob-1 {
          animation: rotatKnob 2s steps(2, end) forwards infinite;
        }

        img.knob-2 {
          animation: rotatKnob 3s steps(4, end) forwards infinite;
          animation-delay: 0.5s;
        }

        .pics img {
          filter: grayscale(10);
          animation: picsMove 5s infinite alternate;
          animation-delay: 0.5s;
        }

        .tv-giphy{
          display: none;
        }
      }
    }
  }

  .navigation {
    position: absolute;
    right: 30px;
    top: 27%;
    width: 74px;
    text-align: center;
    z-index: 10;

    p {
      font-size: 14px;
      color: $black-900;
      font-family: $font-base;
      text-align: center;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      margin-bottom: 30px;

      @media (max-width : $screen-lg ){
        display: none;
      }

      &>span {
        padding: 10px 0;
        display: block;
        animation: 1s arrowToggle 1.5s infinite;
        opacity: 0.7;
      }
    }

    div {
      display: block;
      margin: 30px 0 0 0;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      height: 60px;
      opacity: 0.5;
      cursor: pointer;

      &.i-active,
      &:hover {
        opacity: 1;
      }

      &:nth-of-type(1) {
        margin: 0px 0 0 0;
        background-image: url(../images/banner/moto-sprite.png);
      }

      &:nth-of-type(2) {
        background-image: url(../images/banner/fun-sprite.png);
      }

      &:nth-of-type(3) {
        background-image: url(../images/banner/sofa-sprite.png);
      }

      &:nth-of-type(4) {
        background-image: url(../images/banner/tv-sprite.png);
      }
    }

    @media (max-width : $screen-lg ) {
      display: flex;
      align-items: center;
      width: 100%;
      left: 0;
      right: -10px;
      top: auto;
      bottom: 8vh;
      margin: 0 auto;
      transform: scale(0.75);

      div {
        margin-top: 0;
        background-size: contain;
      }
    }
  }
}
