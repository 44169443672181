
.main-header{
  padding: 16px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.5s ease-out;

  .works & {
    background: #fff;
  }

  .container{
    padding-left: 0;
    padding-right: 0;
  }

  &.is-hidden{
    transform: translateY(-100%);
  }

  .logo{
    height: 100%;
    overflow: hidden;

    img {
      max-width: 100px;
      height: auto;
      transition: all .2s cubic-bezier(.06, 0, 0, 1);

      @include lg{
        max-width: 120px;
      }
    }
  }

  .menu-icon{
    display: flex;
    align-items: center;
    cursor: pointer;
    transform-origin: center center ;
    transition: all 0.5s ease;
    transform: scale(0.8);

    @include lg{
      transform: scale(1);
    }

    .text{
      text-transform: uppercase;
      font-family: $font-base;
      margin-left: 8px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.04em;
    }

    .bars{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bar {
        width: 2px;
        height: 18px;
        margin-right: 3px;
        margin-left: 3px;
        border-radius: 2px;
        background-color: #171717;
        transform-style: preserve-3d;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transition: all 0.5s ease;

        &:nth-child(2) {
          width: 2px;
          height: 28px;
          margin-right: 3px;
          margin-left: 3px;
          border-radius: 2px;
          // opacity: 1;
          transform-origin: center;
          transform: scaleY(1);
        }
      }
    }

    &.active{
      color: #fff;

      .bar{
        background: #fff;

        &:nth-child(1){
          transform: translate3d(8px, 0px, 0px) scale3d(1, 1.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(50deg) skew(0deg, 0deg);
        }

        &:nth-child(2){
          transform: scaleY(0);
        }

        &:nth-child(3){
          transform: translate3d(-8px, 0px, 0px) scale3d(1, 1.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(-50deg) skew(0deg, 0deg);
        }
      }

    }
  }

  &.menu-open{
    background: transparent;

    &:before{
      transform: translateY(-100%);
    }

    .logo img{
      transform: translateY(-60px);
    }
  }
}

#site-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
  z-index: 99;
  opacity: 0;
  pointer-events: none;

  &.show{
    pointer-events: auto;
  }

  .wrapper{
    position: relative;
    // width: 100%;
    width: 100%;
    max-width: 1280px;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    z-index: 10;

    .container{
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      max-width: 1280px;
    }

    .nav-links {
      width: 60%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin: 0 auto;
      font-size: 3vw;

      &:last-child {
        margin-bottom: 0;
      }

      &.o-active {
        color: $primary-500;
      }

      &>div {
        overflow: hidden;
        height: 60px;
        line-height: 60px;
      }

      a {
        font-family: $font-base;
        text-transform: uppercase;
        height: 60px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        letter-spacing: 0.06em;
        transition: all .5s cubic-bezier(.06, 0, 0, 1);
        transform-origin: center;
        position: relative;
        font-weight: 900;
        margin: 10px 0;
        font-size: 36px;
        text-align: center;

        @include lg {
          font-size: 42px;
          margin: 20px 0;
          text-align: left;
        }

        &:nth-child(1) span:after {
          color: color.change(#006046, $lightness: 80%, $space: oklch);
        }

        &:nth-child(2) span:after {
          color: color.change(#F05A7E, $lightness: 80%, $space: oklch);
        }

        &:nth-child(3) span:after {
          color: color.change(#004fe3, $lightness: 70%, $space: oklch);
        }

        &:nth-child(4) span:after {
          color: color.change(#7c3aed, $lightness: 70%, $space: oklch);
        }

        &:nth-child(5) span:after {
          color: color.change(#64748b, $lightness: 80%, $space: oklch);
        }

        span {

          &::before,
          &::after {
            content: attr(data-content);
            display: block;
            transition: all 0.6s cubic-bezier(.06, 0, 0, 1);
            line-height: 60px;
            color: #fff;
          }
        }

        &:hover,
        &.nav-current {

          span::before {
            transform: translate(0px, -100%) perspective(100px);
          }

          span::after {
            transform: translate(0px, -100%) perspective(100px);
          }
        }
      }
    }

    .relative{
      display: none;
      width: 40%;

      @include lg {
        display: flex;
      }
    }

    .nav-option {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 50vh;
      width: 100%;
      z-index: 3;
      transition: all 1.5s cubic-bezier(.18, 1, .21, 1);
      transform: scale(.8);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background: #ccc;
      // padding: 60px 40px 40px;

      &.o-active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);

        .close-button {
          opacity: 1;
        }
      }

      .thumbnail {
        height: 55vh;
        width: 100%;
        position: relative;

        .text {
          font-size: 20px;
          line-height: calc(20px * 1.8);
          line-height: 28px;
          margin-bottom: 30px;
          width: 100%;
          max-width: 400px;
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.04em;
          margin: 0 auto;
          text-align: center;
          padding-top: 30px;
          font-family: $font-base;
        }

        img {
          position: absolute;
          bottom: -25px;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 85%;
          animation: squiggly-anim 0.3s infinite;
        }
      }

      &[nav-data-id="about"] {
        .thumbnail img {
          bottom: -5px;
          max-width: 120%;
        }
      }

      &[nav-data-id="works"] {
        .thumbnail img {
          max-width: 90%;
          bottom: -25px;
          left: 0;
          right: 0;
          margin: auto;
          transform: rotate(-1deg);
        }
      }

      &[nav-data-id="services"] {
        .thumbnail img {
          max-width: 80%;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          @media (max-height: 800px) {
            max-width: 60%;
          }
        }
      }

      &[nav-data-id="clients"] {
        .thumbnail img {
          max-width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          @media (max-height: 800px) {
            // max-width: 60%;
          }
        }
      }
    }
  }


  .contact-info {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    z-index: 10;

    @include lg{
      bottom: 0;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;
      padding: 30px 0;
      max-width: 75%;

      @include lg{
        justify-content: space-between;
      }

      & > div{

        &:first-child{
          display: none;

          @include lg{
            display: block;
          }
        }

        &:last-child{

          @include lg {
            display: block;
          }
        }
      }
    }

    .logo {
      img {
        max-width: 140px;
      }
    }
  }

  .menu-mask{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 10;
    opacity: 1;

    &_item{
      height: 100vh;
      width: 100%;
      position: relative;
    }

    .mask-inner{
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .cc-black{
      background: $black-500;
    }

    .cc-color {
      background: $black-800;
    }
  }
}
