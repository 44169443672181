.home {

  // Portfolio
  .portfolios {

    .list {
      width: 100%;
      padding: 0;
      margin: 0;

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .list img {
      float: left;
    }


    @media screen and (max-width:991px) {
      .list div {
        width: 33.333333%;
      }
    }

    @media screen and (max-width:768px) {
      .list div {
        width: 50%;
      }
    }
  }
}

// Parallax Banner
.parallax-banner {
  height: calc(100vh - 0px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .parallax-banner-inner {
    position: relative;
    width: 100%;
    perspective: 500px;
    padding: 0 20px;

    @include lg{
      padding: 0;
    }
  }

  .image-layer {
    margin: auto;
    position: relative;
    z-index: 2;

    img {
      position: relative;
      height: auto;
      display: block;
      margin: auto;
      max-width: 100%;
      // transform: translateY(-40%);

      @include md{
        max-width: 80%;
      }

      @include lg{
        max-width: 700px;
        transform: none;
        // top: -40px;
      }
    }
  }

  .heading-layer {
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;

    h1.txt {
      position: relative;
      font-family: $font-base;
      z-index: 6;
      font-weight: 900;
      text-align: center;
      width: 100%;
      color: #4d42f8;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 1.4;
      letter-spacing: 0;
      margin: 20px 0;

      br{
        display: none;
      }

      @include md{
        font-size: 36px;
        line-height: calc(36px * 1.4 );

        br {
          display: block;
        }
      }

      @include lg{
        font-size: 4vw;
        line-height: 1.2;
        transform: unset;
        top: 40%;
        margin: 10px 0;

        br {
          display: block;
        }
      }

    }
  }

  .text-layer {
    perspective: 500px;
    transform-style: preserve-3d;

    p {
      font-size: 18px;
      width: 100%;
      text-align: center;
      bottom: -15vh;
      letter-spacing: 0.04em;
      color: $primary-500;
      font-weight: 500;

      @include lg {
        bottom: -5vh;
        transform: unset;
        top: auto;
        position: absolute;
      }
    }
  }

  // Services Page
  .services & {
    .heading-layer h1.txt {
      top: 40%;
    }

    .image-layer img {
      max-width: 800px;
      transform: translateY(-5%);
      mix-blend-mode: lighten;

    }
  }

  // Projects Page
  .projects & {
    .heading-layer h1.txt {
      top: 40%;
    }

    .image-layer img {
      width: 100%;
      max-width: 800px;
      transform: translateY(-5%);
      opacity: 1;
    }
  }
}

.values{
  .value-wrapper {
    position: relative;
    margin: 0 auto;

    .value-card {
      position: relative;
      padding: 20px;
      border: solid 1px $primary-700;
      margin-bottom: 40px;
      border-radius: 8px;
      transition: all .5s cubic-bezier(.06, 0, 0, 1);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: $primary-950;
      }

      & > div:nth-child(n) {
        width: 100%;
      }

      &>div:nth-child(1){
        order: 2;
      }

      &>div:nth-child(2) {
        order: 1;
      }

      &>div:nth-child(3) {
        order: 3;
      }

      &-title {
        h2 {
          text-transform: uppercase;
          letter-spacing: 0.04em;
          font-size: 20px;
          margin-bottom: 4px;
        }
      }

      &-image {
        position: relative;
        text-align: center;
        margin-top: -80px;

        img {
          max-width: 80%;
          display: inline-block;
          animation: squiggly-anim 0.3s infinite;
        }
      }

      @include lg{
        flex-direction: row;
        align-items: center;
        padding: 30px 60px;
        text-align: left;
        margin-bottom: 40px;


        &>div {
          width: 33.33%;

          &:nth-child(1) {
            width: 20%;
            order: 1;
          }

          &:nth-child(2) {
            width: 30%;
            order: 2;
          }

          &:nth-child(3) {
            width: 50%;
            order: 3;
          }
        }

        .value-card-title h2 {
          font-size: 24px;
          margin-bottom: 0;
        }

        .value-card-image{
          margin-top: 0;
        }

        .value-card-image img {
          top: 0;
          bottom: 0;
          position: absolute;
          max-width: 75%;
          margin: auto;
          animation: squiggly-anim 0.3s infinite;
          display: block;
        }
      }
    }
  }
}

.service-item{
  background: #fff;
  padding: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .text{
    text-align: center;
    order: 2;
  }

  img{
    order: 1;
    position: relative;
  }

  @include lg{
    flex-direction: row;
    align-items: start;
    justify-content: start;

    .text{
      order: 1;
      text-align: left;
      width: 60%;
    }

    img{
      order: 2;
      max-width: 260px;
      position: absolute;
      right: -10px;
    }

    &.data img{
      right: -10px;
    }

    &.staff img{
      right: -10px;
    }

    &.supprt img{
      right: -10px;
    }

    &.shopping img{
      right: -10px;
    }
  }
}

.portfolio-content{
  max-width: 80%;
}

.list {
  width: 100%;
  padding: 0;
  margin: 0;

  @media screen and (max-width:991px) {
    .list div {
      width: 33.333333%;
    }
  }

  @media screen and (max-width:768px) {
    .list div {
      width: 50%;
    }
  }
}

.list a {
  display: block;
  float: left;

  img{
    display: block;
    width: 100%;
  }
}

body.services .service{
  h3{
    font-size: 18px;
    left: 0;
  }
}

.clients-carousel {
  position: relative;
  overflow: hidden;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 50px;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    background: linear-gradient(90deg, rgba(#fff, 1) 0%, rgba(#fff, 0) 100%);
    z-index: 5;
  }

  &:after{
    left: auto;
    right: 0;
    background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
  }

  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    position: relative;

    .swiper-slide {
      // border: solid 1px rgba(#000, 0.2);
      padding: 20px 0;

      img {
        display: block;
        max-width: 80px;
        margin: 0 auto;
      }
    }
  }
}

.testimonial-slider-container{

  .swiper-slide{
    background-color: #fff;
    border-radius: 1.5rem;
    flex-flow: column;
    flex: none;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    height: auto;
    display: flex;
    align-items: center;


    &-active {
      box-shadow: 0 26px 46.5px -22px rgba(#000000, 0.2);
    }

    &-shadow {
      background: #fff !important;
      border: 1px solid #e6e6e6 !important;
      border-radius: 1.5rem !important;
    }
  }

  .testimonail_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 1.5rem;
    font-family: $font-base;

    @include lg{
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .testimonial_content{
    padding: 2rem 2.5rem;

    margin: 0 auto;

    @include lg {
      padding: 3rem;
      transform: translate(30px);
      width: 60%;
      flex-direction: row;
    }
  }

  .testimonial_text {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 24px;

    @include lg{
      font-size: 1.6rem;
      letter-spacing: -.56px;
    }
  }

  .testimonial_client-name {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 0.4rem;

    @include lg{
      font-size: 1.5rem;
    }
  }

  .testimonial_designation {
    color: $black-500;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    display: flex;

    & + img{
      max-width: 160px;
      display: none;
    }
  }

  .testimonail_image{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    // display: none;

    @include lg{
      width: 40%;
    }

    img:nth-child(1){
      z-index: 1;
      position: relative;
      // max-width: 400px;
      margin: 0 auto;
    }

    &::after{
      content: "";
      z-index: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../images/logo-image.svg) no-repeat center center;
      background-size: 80%;
      mix-blend-mode: luminosity;
      opacity: 0.3;
    }
  }

  .testimonial_left-arrow,
  .testimonial_right-arrow {
    z-index: 6;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    inset: 0% auto 0% -2%;

    a {
      background-color: #fff;
      border: 1px solid #d6d6d6;
      border-radius: 50%;
      padding: 1rem
    }

    img{
      background-color: #fff;
      border: 1px solid #d6d6d6;
      border-radius: 50%;
      padding: 10px;


      @include lg{
        padding: 1rem;
      }
    }
  }

  .testimonial_right-arrow {
    inset: 0% -2% 0% auto;
  }
}

.domain{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff;
  padding: 24px 0;

  img{
    display: block;
    margin-bottom: 16px;
    max-width: 80px;
    height: auto;
  }

  p{
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
  }
}

.grid .client{
  border: solid 1px $primary-200;
  border-top: none;
  border-left: none;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  background: #fff;
  overflow: hidden;

  @include lg{
    &:nth-child(4n+4) {
      border-right: none;
    }

    &:nth-child(n+21) {
      border-bottom: none;
    }
  }

  img{
    max-width: 80px;
    height: auto;
    transition: all 0.4s $ease;
  }
}


.float{
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: $primary-500;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg{
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}
