#loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: $primary-100;
  pointer-events: none;
  // display: none;

  body:not(.home) &{
    display: none;
    
    // #progress {
    //   display: none;
    // }
  }

  #progress {
    width: 90%;
    position: relative;
    max-width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    z-index: 10;
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;

    .image{

      svg {
        max-width: 100px;
        width: 100%;
        height: auto;
      }
    }

    .text {
      overflow: hidden;

      svg {
        width: 100%;
        max-width: 140px;
        height: auto;
      }
    }
  }

  .progress-bar-wrap {
    width: 90%;
    max-width: 400px;
    height: 5px;
    border-radius: 5px;
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
  }

  #progress-bar {
    width: 100%;
    height: 100%;
    background-color: $primary-500;
    transform: translateX(-100%);
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.3s linear;
    background: $black-800;
    transform-origin: top top;

    &:nth-child(1) {
      background: $black-800;
      z-index: 5;
    }

    &:nth-child(2) {
      background: $black-700;
      z-index: 4;
    }

    &:nth-child(3) {
      background: $black-600;
      z-index: 3;
    }

    &:nth-child(4) {
      background: $black-500;
      z-index: 2;
    }

    &:nth-child(5) {
      background: $black-400;
      z-index: 1;
    }
  }
}
