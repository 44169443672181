// Lightbox

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: .8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  font-weight: 400;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border: 1px solid #fff;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  margin: 0 auto;
  background-color: #fff;
}

.lb-outerContainer:after {
  display: table;
}

.lb-loader,
.lb-nav {
  position: absolute;
  left: 0;
}

.lb-loader {
  top: 43%;
  height: 25%;
  width: 100%;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container>.nav {
  left: 0;
}

.lb-nav a {
  outline: 0;
}

.lb-next,
.lb-prev {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
}

.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
}

.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  top: 6px;
  position: absolute;
  right: 0;
  left: 0;
}

.lb-dataContainer:after {
  display: table;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
  display: none;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  text-align: center;
  outline: 0;
  position: absolute;
  right: 15px;
  z-index: 100;
  top: 20px;
  cursor: pointer;
  transform-origin: top center;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

.lb-data .lb-close:before,
.lb-data .lb-close:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-color: #FFF;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.lb-data .lb-close:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lb-data .lb-close:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
