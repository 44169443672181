.btn{
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  justify-content: space-between;
  text-transform: uppercase;
  transition: all .5s cubic-bezier(.06, 0, 0, 1);
  cursor: pointer;
  display: inline-block;
  background: $black-900;

  &:hover, &:active{
    transform: scale(0.95);
    transform-origin: center center;
    background: $primary-500;
  }

  &-inner{
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 30px 0 25px;
    height: 46px;

    .btn-mask{
      height: 100%;
    }

    .btn-text{
      // height: 100%;
      // padding: 16px 0;
      line-height: 46px;
      transition: all .5s cubic-bezier(.06, 0, 0, 1);
      letter-spacing: 0.04em;
    }

    .btn-icon{
      margin-left: 15px;
      // transform: scale(0.75);
      transform-origin: center center;
      transition: all .5s cubic-bezier(.06, 0, 0, 1);
      width: 18px;
      height: auto;

      svg {
        fill: #fff;
      }
    }

    &:hover{

      .top{
        transform: translate(0px, -100%);
      }

      .bottom{
        transform: translate(0px, -100%);
      }

      .btn-icon{
        transform: rotate(-45deg);
      }
    }
  }

  &.btn-primary{
    color: $black-900;
    background: #fff;

    .btn-icon svg{
      fill: $black-800;
    }

    .btn-text.bottom{
      color: #fff;
    }

    &:hover,
    &:active {
      transform: scale(0.95);
      transform-origin: center center;
      background: $primary-500;

      .btn-icon svg {
        fill: #fff;
      }
    }
  }

  &.btn-link{
    background: transparent;
    color: $primary-500;
    font-size: 14px;
    display: inline-block;
    align-items: center;
    height: 30px;
    overflow: hidden;
    padding-left: 0;
    position: relative;
    padding-right: 28px;

    span{

      &::before,
      &::after {
        content: attr(data-text);
        display: block;
        line-height: 30px;
        transition: all 0.6s cubic-bezier(.06, 0, 0, 1);
      }

      &::after{
        color: $sec-900;
      }
    }

    i {
      margin-left: 16px;
      position: absolute;
      margin: auto;
      top: -3px;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;
      text-align: center;
      transition: all .5s cubic-bezier(.06, 0, 0, 1);
      transform-origin: center;

      svg {
        fill: $primary-500;
        width: 100%;
        height: auto;
      }
    }

    &:hover {
      transform: none;

      span::before {
        transform: translate(0px, -100%) perspective(100px);
      }

      span::after {
        transform: translate(0px, -100%) perspective(100px);
      }

      i{
        transform: rotate(-45deg);

        svg{
          fill: $sec-900;
        }
      }
    }
  }
}
