footer{
  // background-image: url(./assets/images/logo-image.png) no-repeat center center;
  background: $slate-950;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:before{
    content:"";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    background: url(../images/logo-image.png) no-repeat center center;
    background-size: 300px;
    filter: grayscale(1);
    mix-blend-mode: color-dodge;
    opacity: 0.2;
    top: -50px;
  }

  .container:first-child{
    display: flex;
    align-items: center;
    padding: 20vh 0;

    & > div{
      width: 100%;
    }
  }

  .container:last-child{
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ctoa{
    max-width: 90%;
    text-align: center;


    h2 {
      font-size: 36px;
      line-height: 1.4;
      text-align: center;

      @include lg{
        font-size: 4vw;
        line-height: 1.2;
      }
    }

    p{
      font-size: 18px;
    }

  }

  .links{
    color: $black-400;
    text-align: center;

    a{
      padding: 0 10px;
      transition: all 0.4s linear;
      margin-bottom: 10px;
      display: inline-block;

      &:hover {
        fill: $black-100;
      }
    }
  }

  .copy p{
    color: $black-400;
    font-size: 16px;
  }

  .social-media a{
    svg {
      fill: $black-400;
    }

    &:hover svg{
      fill: $black-100;
    }
  }
}
