
// Squggly animation

.squigle{
  animation: squiggly-anim 0.3s infinite;
}

//  Accordion

dl.accordion{
  background: #fff;
  border: 1px solid $black-300;

  dt {
    margin: 0;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-family: $font-base;
    color: $slate-800;
    font-weight: bold;
    border-bottom: 1px solid $black-300;
    position: relative;
    padding-right: 30px;
    padding-left: 20px;
    transition: transform .2s $ease;

    &:hover{
      color: $primary-500;
    }

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      background: #adb5bd;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: transform .2s $ease;
    }

    &:before {
      width: 2px;
      height: 14px;
      right: 26px;
    }

    &:after {
      height: 2px;
      width: 14px;
      right: 20px;
    }

    &.active{
      color: $primary-500;

      &:before {
        transform: rotate(90deg);
      }
    }
  }

  dd {
    display: none;
    padding: 20px;
    color: $slate-500;
    border-bottom: 1px solid $black-300;
    line-height: 1.8;
    width: 100%;

    &:last-of-type{
      border-bottom: none;
    }
  }

  &.active dd{
    display: block;
  }
}

// Process

.process {
  font-family: $font-base;
  position: relative;
  z-index: 1;
  margin: auto -15px;

  div {
    height: 100%;
    border: solid 1px $primary-200;
    transition: all 0.3s linear;
    font-family: $font-base;
    color: $black-600;
    text-align: center;
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(#fff, 1);

    img{
      max-width: 60%;
      margin-bottom: 16px;
    }

    h3{
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 10px;
      color: $primary-500;
      font-family: $font-base;
    }

    p{
      font-size: 16px;
      text-transform: none;
      line-height: 1.8;
      font-family: $font-serif;
      color: $slate-500;
    }

    &:hover{
      background: rgba(#fff, 1);
    }
  }
}

// Spinners

.spinner-border {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 6px solid #22c55e;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

// Social Media

.social-media {
  display: flex;
  align-items: center;
  position: relative;

  a {
    position: relative;
    display: block;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {

      svg {
        transform: scale(1.2);
      }
    }

    svg {
      fill: #fff;
      display: block;
      width: 18px;
      height: auto;
      transition: all .5s cubic-bezier(.06, 0, 0, 1);
    }

    &:before {
      content: "";
      position: relative;
      width: 30px;
      height: 30px;
    }

    &.facebook {
      &:before {
        position: relative;
      }
    }

    &.twitter {
      &:before {
        position: relative;
      }
    }

    &.linkedin {
      &:before {
        position: relative;
      }
    }

    &.instagram {
      &:before {
        position: relative;
      }
    }

  }
}
