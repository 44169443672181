.hello-form{
  font-family: $font-base;
  min-height: 600px;
  padding: 120px 0;

  @include lg{
    padding-top: 100px;
    min-height: 90vh;
  }

  @include xl{
    min-height: 800px;
  }

  form{

    @include lg{
      padding-right: 30px;
    }

    @include xl{
      padding-right: 60px;
    }
  }

  .form-group{

    h2{
      font-size: 28px;
      line-height: 1.4;

      @include lg {
        font-size: 42px;
        padding-top: 20px;
      }

      @include xl{
        font-size: 3vw;
        line-height: 1.4;
        padding-top: 0;
      }
    }

    h4{
      margin-bottom: 36px;
      font-size: 24px;
      line-height: calc(24px * 1.6);
      color: $black-700;
      font-weight: 400;
    }

    & > div{
      font-size: 24px;
      font-weight: 400;
      line-height: calc( 24px * 1.6);

      @include lg{
        font-size: 3vw;
        font-weight: 400;
        line-height: calc(3vw * 1.6);
      }

      @include xl {
        font-size: 2vw;
        font-weight: 400;
        line-height: calc(2vw * 1.6);
      }

      span{
        position: relative;

        span.required{
          position: absolute;
          top: 0;
          right: 10px;
          color: #f00;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }

    input, textarea{
      border-bottom: dashed 1px $slate-400;

      &::placeholder {
        color: $primary-500;
        opacity: 0.5;
        transition: all 0.4s $ease;
      }

      &:focus, &:active{
        color: $primary-500;
        border-bottom: dotted 1px $slate-800;
        box-shadow: none;
        outline: none;

        &::placeholder {
          opacity: 0;
        }
      }
    }

    input{
      width: auto;
    }
  }

  .textarea{
    display: block;

    textarea{
      display: block;
      width: 100%;
      resize: none;
    }
  }
}


// ccba llje eahs lirm
