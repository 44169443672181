@keyframes lineForward {
  0% {
    transform: translate3d(0, 0, 100%)
  }

  to {
    transform: translateZ(0)
  }
}

@keyframes lineBackward {
  0% {
    transform: translateZ(0)
  }

  to {
    transform: translate3d(100%, 0, 0)
  }
}

@keyframes glitch-color {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes lightOpacity {
  0%, 60%, 80% { opacity: 0; }
  40%, 70%, 100% { opacity: 1; }
}

@keyframes lightFade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include keyframes(smoke) {
  0% {
    transform: translateX(0) scale(0.2);
  }

  100% {
    transform: translateX(250px) scale(3);
    opacity: 0
  }
}

@include keyframes(scale) {
  0% {
    transform: scale(0.6) rotate(0deg);
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1.8) rotate(90deg);
    opacity: 0;
  }
}

@include keyframes(rotate) {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@include keyframes(rotatKnob) {
  to {
    transform: rotate(60deg);
  }
}

@include keyframes(picsMove) {
  0% {
    transform: translateY(10px);
  }

  25% {
    transform: translateY(-290px);
  }

  50% {
    transform: translateY(-590px);
  }

  75% {
    transform: translateY(-890px);
  }

  100% {
    transform: translateY(-1190px);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
    background-color: #65cbfb
  }

  50% {
    opacity: 1;
    background-color: #b6caf1
  }

  100% {
    opacity: 1;
    background-color: #d579ff
  }
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(var(--marquee));
  }
}

@keyframes flicker {
  0%, 90% {
    opacity: 0;
  }

  50%, 80%{
    opacity: 1;
  }
}

@keyframes fanWing {
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}

@keyframes fanAir {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.4);
  }
}

@keyframes sofaExpand {


  0% {
    width: 0px;
  }

  30% {
    width: 0px;
  }

  60% {
    width: 100px;
  }

  100%{
    width: 220px;
  }
}

@keyframes squiggly-anim {
  0% {
    -webkit-filter: url("#squiggly-0");
    filter: url("#squiggly-0");
  }

  25% {
    -webkit-filter: url("#squiggly-1");
    filter: url("#squiggly-1");
  }

  50% {
    -webkit-filter: url("#squiggly-2");
  }

  75% {
    -webkit-filter: url("#squiggly-3");
    filter: url("#squiggly-3");
  }

  100% {
    -webkit-filter: url("#squiggly-4");
    filter: url("#squiggly-4");
  }
}

@keyframes scrollanime {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-50%);
  }
}
