@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@mixin keyframes($animation) {
  @-webkit-keyframes #{$animation} {
    @content;
  }

  @-moz-keyframes #{$animation} {
    @content;
  }

  @-o-keyframes #{$animation} {
    @content;
  }

  @keyframes #{$animation} {
    @content;
  }
}

@mixin abs-center {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

@mixin backface {
  -wibkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

// Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}
