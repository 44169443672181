$font-size-base: 16px;
$font-size-sm: 14px;
$font-base: "Satoshi", sans-serif;
$font-serif: "Inter";
$browser-context: 16;

$line-height-base: 1.5;
$font-baseline: $line-height-base * 1rem;

$brand-col: #d843ff;
$pri-col: #d843ff;
$sec-col: #fff;

// Screen Size
$screen-sm : 640px;
$screen-md : 768px;
$screen-lg : 1024px;
$screen-xl : 1280px;
$screen-xxl : 1366px;

// Easings

$ease : cubic-bezier(.06, 0, 0, 1);

// Colors

$primary-50   : #fdf3ff;
$primary-100  : #f9e6ff;
$primary-200  : #f4ccff;
$primary-300  : #eea3ff;
$primary-400  : #e66dff;
$primary-500  : #d843ff;
$primary-600  : #bd16e3;
$primary-700  : #9f0ebd;
$primary-800  : #840e9a;
$primary-900  : #70117e;
$primary-950  : #490055;

$sec-50   : #F2F2F6;
$sec-100  : #E6E6EE;
$sec-200  : #CCCCDC;
$sec-300  : #B4B3CC;
$sec-400  : #9A99BA;
$sec-500  : #807FA8;
$sec-600  : #676797;
$sec-700  : #4E4E86;
$sec-800  : #353475;
$sec-900  : #1C1B64;
$sec-950  : #020152;

$black-50   : #f8fafc;
$black-100  : #f1f5f9;
$black-200  : #e2e8f0;
$black-300  : #cbd5e1;
$black-400  : #94a3b8;
$black-500  : #64748b;
$black-600  : #475569;
$black-700  : #334155;
$black-800  : #1e293b;
$black-900  : #0f172a;
$black-950  : #020617;

$slate-50   : #f9fafb;
$slate-100  : #f3f4f6;
$slate-200  : #e5e7eb;
$slate-300  : #d1d5db;
$slate-400  : #9ca3af;
$slate-500  : #6b7280;
$slate-600  : #4b5563;
$slate-700  : #374151;
$slate-800  : #1f2937;
$slate-900  : #111827;
$slate-950  : #030712;
