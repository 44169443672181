html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: $font-size-base;
}

body {
  font-family: $font-serif;
  margin: 0;
  font-size: $font-size-base;
  font-optical-sizing: auto;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $slate-700;
}

body {

  h1, h2, h3, h4 {
    font-weight: 900;
    font-family: $font-base;

    &.display-2 {
      font-size: 26px;
      font-weight: 900;
      line-height: 1.4;
      letter-spacing: 0.02em;

      @include lg{
        font-size: 3vw;
      }
    }
  }

  h1, .h1{
    font-size: 32px;
    line-height: calc(32px * 1.3);


    @include lg {
      font-size: 40px;
      line-height: calc(40px * 1.4);
    }

    @include xl {
      font-size: 48px;
      line-height: calc(48px * 1.4);
    }
  }

  h2, .h2 {
    font-size: 24px;
    line-height: calc(24px * 1.4);

    @include lg {
      font-size: 32px;
      line-height: calc(32px * 1.4);
    }

    @include xl {
      font-size: 36px;
      line-height: calc(34px * 1.4);
    }
  }

  h3, .h3 {
    font-size: 18px;
    line-height: calc(18px * 1.5);

    @include md {
      line-height: calc(18px * 1.5);
    }

    @include lg {
      font-size: 20px;
      line-height: calc(20px * 1.4);
    }

    @include xl {
      font-size: 24px;
      line-height: calc(24px * 1.4);
    }
  }

  h4, .h4 {
    font-size: 18px;
    line-height: calc(18px * 1.5);

    @include md {
      line-height: calc(18px * 1.5);
    }

    @include lg {
      font-size: 20px;
      line-height: calc(20px * 1.4);
    }
  }

  .text-lead {
    font-size: 18px;
    font-weight: 500;
    line-height: calc(18px * 1.4);
    letter-spacing: 0.01em;

    @include lg {
      font-size: 18px;
      line-height: calc(18px * 1.4);
    }

    @include xl {
      font-size: 20px;
      line-height: calc(20px * 1.6);
    }
  }

  p {
    font-size: 16px;
    line-height: calc(16px * 1.8);
  }
}
