.portfolio-page .page-header {
  background: url("../images/bg-portfolio.jpg") no-repeat;
  position: relative;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  background-size: contain;
}

.portfolio-page .portfolio-grid {
  max-width: 100%;
  zoom: 1;
  margin-left: -1%;
  position: relative;
  // display: grid;
}

.portfolio-page .portfolio-grid:before,
.portfolio-page .portfolio-grid:after {
  content: "";
  display: table;
}

.portfolio-page .portfolio-grid:after {
  clear: both;
}

.portfolio-page .portfolio-selector {
  overflow: hidden;
}

.portfolio-page .portfolio-selector img {
  width: 100%;
  display: block;
  position: relative;
  -wibkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.portfolio-page .portfolio-selector .overlay {
  position: absolute;
  overflow: hidden;
  width: 95%;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto;
  text-align: center;
  background-color: rgba(26, 26, 26, 0.9);
  color: #fff;
  padding: 15px 0;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s cubic-bezier(1, 0.42, 1, 1);
  -o-transition: all 0.3s cubic-bezier(1, 0.42, 1, 1);
  transition: all 0.3s cubic-bezier(1, 0.42, 1, 1);
}

.portfolio-page .portfolio-selector .overlay p {
  margin-bottom: 0;
  color: #fff !important;
}

.portfolio-page .portfolio-selector h2 {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  font-size: 18px;
  background-color: transparent;
  color: #FFF;
  padding: 0.2em 0;
  margin-bottom: 22px;
  letter-spacing: 1px;
}

.portfolio-page .portfolio-selector a {
  color: #FFF;
  padding: 0em 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.portfolio-page .portfolio-selector:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}


.portfolio-page .pf1,
.portfolio-page .pf2,
.portfolio-page .pf3,
.portfolio-page .pf4,
.portfolio-page .pf5,
.portfolio-page .pf6,
.portfolio-page .pf7 {
  width: 25%;
  padding: 10px;
}

.portfolio-page .portfolio-grid .portfolio-selector a h2::after,
.portfolio-page .portfolio-grid .portfolio-selector h2::before {
  background-color: #fff;
  content: "";
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
}

.portfolio-page .portfolio-grid .portfolio-selector h2::before {
  bottom: -10px;
  width: 100px;
}

.portfolio-page .portfolio-grid .portfolio-selector h2::after {
  bottom: -15px;
  width: 60px;
}

.portfolio-page .portfolio-grid .portfolio-selector p {
  text-align: center;
  color: #fff;
  font-size: 15px;
  margin-top: 5px;
}

.portfolio-page .lb-loader,
.portfolio-page .lightbox {
  text-align: center;
  line-height: 0;
}

.portfolio-page .lb-dataContainer:after,
.portfolio-page .lb-outerContainer:after {
  content: "";
  clear: both;
}

.portfolio-page body.lb-disable-scrolling {
  overflow: hidden;
}

.portfolio-page{

  .button-group{
    display: flex;
    justify-content: center;  
    gap: 10px
  }

  .button {
    display: inline-block;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 50px;
    letter-spacing: 0.04em;
    position: relative;
    padding-left: 40px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    transition: color 200ms;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 20px;
      height: 1px;
      border-bottom: solid 1px;
    }

    &:first-child:before {
      display: none;
    }
  
    &.is-checked {
      color: #f79622;
    }
  }
}
